/*******************
* Global
*******************/
html {
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 100vh;
}

a {
  color: #2C5378;
}

.mailto:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}

.tel:after {
  content: attr(data-tel);
}

/*******************
* Header
*******************/
.app__header {
  width: 100%;
  transition: top 300ms linear;
}

.app__header__container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px 40px;
  width: calc(100% - 80px);
  max-width: 1120px;
}

.app__title {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, .75);
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}

.app__logo {
  width: 52px;
  height: 52px;
}

.app__brand {
  margin-top: 10px;
}

.app__nav {
  margin-left: auto;
  margin-bottom: 0;
  padding-left: 0;
}

.app__nav__list {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

.app__nav__item {
  margin-right: 20px;
}

.app__nav__item:last-child {
  margin-right: 0;
}

.app__nav__item > .app__nav__item__link {
  display: block;
  font-size: 16px;
}

.app__nav__item--language>.app__nav__item__link {
  color: #213337;
}

.app__nav__item--login > .app__nav__item__link {
  background-color: #2C5378;
  border-radius: 3px;
  color: #fff;
  padding: 10px 20px;
}

.app__nav__item--login > .app__nav__item__link:hover {
  background-color: #6FADF1;
}

.app__nav__item--desktop>.app__nav__item__link {
  border: 1px solid #213337;
  border-radius: 3px;
  color: #213337;
  padding: 0 20px;
}

/*******************
* Footer
*******************/
.app__nav--footer .app__nav__item__link {
  font-size: 1em;
  text-decoration: underline;
  color: #213337;
  white-space: nowrap;
}

.app__nav--footer .app__nav__item__link .app__nav__item__link__image {
  position: absolute;

  width: 16px;
  height: 16px;
}

.app__nav--footer .app__nav__item__link:hover {
  text-decoration: none;
}

.app__footer {
  width: 100%;
}

.app__footer__container {
  display: flex;
  align-items: center;
  padding: 15px 40px;
  margin: 0 auto;
  width: calc(100% - 80px);
  max-width: 1120px;
}

.app__copyright {
  margin: 0;
}

.app__content {
  flex: 1;
}

/*******************
* Infos
*******************/
.app__infos {
  width: 1120px;
  margin: 0 auto;
  padding: 20px 40px;
}

.app__infos--hero {
  padding-top: 60px;
  padding-bottom: 60px;
}

/*******************
* GDPR
*******************/
.has-gpdr {
  overflow: hidden;
  touch-action: none;
}

.gdpr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
}

.gdpr__overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: .5;
}

.gdpr__banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 20px);
  background-color: #2C5378;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
}

.gdpr__banner p > a {
  color: #fff;
  text-decoration: underline;
}

.gdpr__banner p > a:hover {
  text-decoration: none;
}

.gdpr__banner p > a.gdpr__banner__button {
  margin-left: 10px;
  text-decoration: none;
}

.gdpr__banner__message {
  color: #fff;
  margin-bottom: 0;
  width: 1120px;
}

/*******************
* Go Up
*******************/
.go-up {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #213337;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; height: 40px;
}

.go-up > img {
  width: 24px; height: 24px;
}

/*******************
* Sticky
*******************/
.sticky .app {
  padding-top: 96px;
}

.sticky .app__header {
  position: fixed;
  top: 0; left: 0;
  z-index: 999;
  background-color: #fff;
  border-bottom: 1px solid #DDE2E7;
}


@media (max-device-width: 1024px) {
  .app {
    padding-bottom: 0;
  }
  .app__infos {
    width: calc(100% - 80px);
  }
}

@media (max-device-width: 768px) {
  .app__header .app__nav__list {
    display: none;
  }

  .app__nav__hamburger {
    position: relative;
    z-index: 999;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px; height: 20px;
    z-index: 999;
  }

  .app__nav__hamburger__line {
    display: block;
    background-color: #D9D9D9;
    width: 25px; height: 3px;
    transition: transform linear 300ms, opacity linear 300ms;
  }

  .app__nav__hamburger:hover > .app__nav__hamburger__line {
    background-color: #2C5378;
  }

  .menu-opened {
    overflow: hidden !important;
    touch-action: none;
  }

  .sticky.menu-opened .app__header {
    height: 100%;
  }

  .menu-opened .app__header .app__nav .app__nav__list {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .75);
    margin: 0;
    padding: 90px 0 0;
    width: 100%;
    height: 100%;
    z-index: 888;
    overflow: hidden;
  }

  .menu-opened .app__header .app__nav .app__nav__item {
    margin: 0;
  }

  .menu-opened .app__header .app__nav .app__nav__item:first-child {
    border-top: 1px solid #fff;
  }

  .menu-opened .app__header .app__nav .app__nav__item__link {
    display: block;
    color: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #fff;
  }

  .menu-opened .app__header .app__nav .app__nav__item--login {
    padding: 10px 20px;
  }

  .menu-opened .app__header .app__nav .app__nav__item--login .app__nav__item__link {
    border-bottom: none;
    text-align: center;
  }

  .menu-opened .app__header .app__nav .app__nav__hamburger {
    position: relative;
  }

  .menu-opened .app__header .app__nav .app__nav__hamburger__line--1 {
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
  }

  .menu-opened .app__header .app__nav .app__nav__hamburger__line--3 {
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
  }

  .menu-opened .app__header .app__nav .app__nav__hamburger__line--2 {
    opacity: 0;
  }
}

@media only screen and (max-device-width: 667px) and (orientation: portrait) {
  .app__header {
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% - 40px);
  }

  .app__header__container {
    flex-wrap: wrap;
    padding: 20px 0;
    width: 100%;
  }

  .app__infos--hero {
    flex-wrap: wrap;
  }
  
  .app__infos--contact > .app__infos__content {
    flex-wrap: wrap;
  }

  .app__footer {
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% - 40px);
  }

  .app__footer__container {
    flex-wrap: wrap;
    padding: 20px 0;
    width: 100%;
  }

  .app__copyright {
    margin-bottom: 20px;
  }

  .app__footer .app__nav {
    margin-left: 0;
  }

  .app__nav__list {
    padding-left: 0;
  }
}

@media all and (-ms-high-contrast:none) {
  .app {
    display: block;
  }
}
